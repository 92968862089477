import {useApiRequestMappers} from "@/shared/composables/use-api-request-mappers";
import {useApplicationContext} from "@/shared/composables/use-application-context";

export const useGroupApi = () => {
  const {logger, httpClient} = useApplicationContext();
  const {keysToSnakeCase, keysToCamel} = useApiRequestMappers();

  const createGroupWithBasicInformation = async (data) => {
    const requestData = keysToSnakeCase(data);
    delete requestData.group_category;

    try {
      logger.info(`Creating group (${requestData.name})`);
      logger.debug('Group basic information sent to the API to create group', requestData);
      const response = await httpClient.post('/groups', {...requestData});
      return response.headers.location.split("/")[5];
    } catch (error) {
      logger.error(`Creating group (${requestData.name}) request failed`, error);
      throw  error;
    }
  };

  const deleteGroup = async (id) => {
    try {
      logger.info(`Deleting group (${id})`);
      logger.debug('Group request sent to the API to delete group');
      return await httpClient.delete(`/groups/${id}`);
    } catch (error) {
      logger.error(`Deleting group (${id}) request failed`, error);
      throw  error;
    }
  };

  const getGroupBasicInformation = async (id, publicAccessToken) => {
    let url =`/groups/${id}`;

    if (publicAccessToken) {
      url += `?token=${publicAccessToken}`;
    }
    try {
      logger.info(`Fetching basic information for group (${id})`);
      const response = await httpClient.get(url);
      const groupBasicInformation = keysToCamel(response.data);

      logger.debug(`Retrieved group (${id}) basic information`, groupBasicInformation);
      return groupBasicInformation;
    } catch (error) {
      logger.error(`Failed to fetch group (${id}) basic information`, error);
      throw  error;
    }
  };

  const getAllAvailableGroups = async () => {
    try {
      logger.info(`Fetching all available groups for current user`);
      const response = await httpClient.get(`/groups`);
      const groups = keysToCamel(response.data);

      logger.debug(`Retrieved (${groups.length}) group(s)`, groups);
      return groups;
    } catch (error) {
      logger.error(`Failed to fetch available groups`, error);
      throw  error;
    }
  };

  const updateGroupBasicInformation = async (id, data) => {
    //Cannot be updated by this call

    const requestData = keysToSnakeCase(data);

    //Cannot be updated by this call
    delete requestData['access_status'];
    delete requestData['allowed_agencies'];
    delete requestData['id'];
    delete requestData['sale_status'];
    delete requestData['status'];
    delete requestData['public_access_token'];

    try {
      logger.info(`updating group (${id}/${requestData.name})`);
      logger.debug('Group basic information sent to the API to update group', requestData);
      await httpClient.put(`/groups/${id}`, {...requestData});
      return id;
    } catch (error) {
      logger.error(`Updating group (${id}/${requestData.name}) request failed`, error);
      throw  error;
    }
  };

  const updateGroupAdvancedInformation = async (id, data) => {
    const requestData = keysToSnakeCase(data);

    try {
      logger.info(`updating group (${id}) advanced information`);
      logger.debug('Group advanced information sent to the API to update group', requestData);
      await httpClient.put(`/groups/${id}/advanced-information`, {...requestData});
    } catch (error) {
      logger.error(`Updating group (${id}/${requestData.name}) request failed`, error);
      throw  error;
    }
  };

  const getGroupAdvancedInformation = async (id) => {
    try {
      logger.info(`Fetching advanced information for group (${id})`);
      const response = await httpClient.get(`/groups/${id}/advanced-information`);
      const groupAdvancedInformation = keysToCamel(response.data);

      logger.debug(`Retrieved group (${id}) advanced information`, groupAdvancedInformation);
      return groupAdvancedInformation;
    } catch (error) {
      logger.error(`Failed to fetch group (${id}) advanced information`, error);
      throw  error;
    }
  };

  
  const getGroupTransportOptions = async (id) => {
    try {
      logger.info(`Fetching transport option for group (${id})`);
      const response = await httpClient.get(`/groups/${id}/transportations`);
      const groupTransportOptions = keysToCamel(response.data);

      logger.debug(`Retrieved group (${id}) transport option`, groupTransportOptions);
      return groupTransportOptions;
    } catch (error) {
      logger.error(`Failed to fetch group (${id}) transport option`, error);
      throw  error;
    }
  };

  const getGroupAccommodationOptions = async (id) => {
    try {
      logger.info(`Fetching accommodation options for group (${id})`);
      const response = await httpClient.get(`/groups/${id}/accommodations`);
      const groupAccommodationOptions = (response.data);

      logger.debug(`Retrieved group (${id}) accommodation option`, groupAccommodationOptions);
      return groupAccommodationOptions;
    } catch (error) {
      logger.error(`Failed to fetch group (${id}) accommodation option`, error);
      throw  error;
    }
  };

  const uploadImage = async (data) => {
    try {
      logger.info(`Uploading image (${data.name}) `);
      logger.debug(`Uploading image (${data.name}) in debug`, data);

      const response = await httpClient.post(`/media`, data);
      return response;
      
    } catch (error) {
      logger.error(`Uploading image`, error);
      throw  error;
    }
  };

  const uploadDocument = async (data) => {
    try {
      logger.info(`Uploading document (${data.name}) `);
      logger.debug(`Uploading document (${data.name}) in debug`, data);

      const response = await httpClient.post(`/media`, data);
      return response;
      
    } catch (error) {
      logger.error(`Uploading document`, error);
      throw  error;
    }
  };

  const updateGroupStatus = async (id, data) => {
    const requestData = keysToSnakeCase(data);

    try {
      logger.info(`updating group (${id}) statuses`);
      logger.debug('Group statuses sent to the API to update group', requestData);
      await httpClient.put(`/groups/${id}/statuses`, {...requestData});
    } catch (error) {
      logger.error(`Updating group statuses (${id}) request failed`, error);
      throw  error;
    }
  };

  const updateAccessGroupStatus = async (id, data) => {
    const requestData = keysToSnakeCase(data);

    try {
      logger.info(`updating group (${id}) statuses`);
      logger.debug('Group statuses sent to the API to update group', requestData);
      await httpClient.put(`/groups/${id}/statuses/agencies`, {...requestData});
    } catch (error) {
      logger.error(`Updating group statuses (${id}) request failed`, error);
      throw  error;
    }
  };

  const cloneGroup = async (id) => {
    try {
      logger.info(`cloning group (${id})`);
      logger.debug('Group request sent to the API to clone group');
      return await httpClient.post(`/groups/${id}/actions/clone`);
    } catch (error) {
      logger.error(`Cloning group (${id}) request failed`, error);
      throw  error;
    }
  };

  const addItineraryDayToGroup = async (id, data) => {
    try {
      let object = formatItineraryDayObject(data)

      logger.info(`adding day (${object.title}) `);
      logger.debug(`adding day (${object.title}) in debug`, object);

      const response = await httpClient.post(`/groups/${id}/itineraries`, object);
      return response;
    } catch (error) {
      logger.error(`Uploading image`, error);
      throw  error;
    }
  }

  const updateItineraryDay = async (group_id, itinerary_id, data) => {
    try {
      let object = formatItineraryDayObject(data)

      logger.debug(`updating day (${object.title}) in debug`, object);

      const response = await httpClient.put(`/groups/${group_id}/itineraries/${itinerary_id}`, object);
      return response;
    } catch (error) {
      logger.error(`updating day`, error);
      throw  error;
    }
  }

  const deleteItineraryDay = async (group_id, itinerary_id, data) => {
    try {
      logger.debug(`deleting day (${data.title}) in debug`, data);

      const response = await httpClient.delete(`/groups/${group_id}/itineraries/${itinerary_id}`);
      return response;
    } catch (error) {
      logger.error(`deleting day`, error);
      throw  error;
    }
  }

  const getReservations = async (id) => {
    try {
      const response = await httpClient.get(`/groups/${id}/reservations`)
      const reservations = response.data;

      logger.debug(`Retrieved group (${id}) reservations`, reservations);
      return reservations;
    } catch (error) {
      logger.error(`Failed to fetch group (${id}) reservations`, error);
      throw  error;
    }
  };

  const getOrgReservations = async (params) => {
    try {
      let url = `/organization/reservations?page=${params.page - 1}&size=${params.perPage}`;

      if (params.sort.field && params.sort.type) {
        url = `${url}&sort=${params.sort.field}&order=${params.sort.type}`
      }

      if (params.searchTerm) {
        url = `${url}&s=${params.searchTerm}`
      }

      const response = await httpClient.get(url)
      const reservations = response.data;

      logger.debug(`Retrieved organization  reservations`, reservations);
      return reservations;
    } catch (error) {
      logger.error(`Failed to fetch organization  reservations`, error);
      throw  error;
    }
  };

  const getDocuments = async (id, publicAccessToken) => {
    let url = `/groups/${id}/documents`;

    if (publicAccessToken != null) {
      url = `/groups/${id}/documents?token=${publicAccessToken}`
    }

    try {
      logger.info(`Fetching documents for group (${id})`);
      const response = await httpClient.get(url)
    const documents = response.data;
      /*
    const documents = [{
        filename: "Excursion pour le groupe",
        category: "Excursion",
        uploaded_by: "Marc-Olivier",

      }];
        */
      logger.debug(`Retrieved group (${id}) documents`, documents);
      return documents;
    } catch (error) {
      logger.error(`Failed to fetch group (${id}) documents`, error);
      throw  error;
    }
  };

  const deleteDocument = async (group_id, document) => {
    try {


      logger.info(`deleting document (${document}) `);
      logger.debug(`deleting document (${document}) in debug`, document);

      const response = await httpClient.delete(`/groups/${group_id}/documents/${document}`);
      return response;
      
    } catch (error) {
      logger.error(`deleting document`, error);
      throw  error;
    }
  }

  const addDocumentToGroup = async (id, data) => {
    try {
      let document = {    
        "is_org_only": data.is_org_only,
        "link": data.url,
        "name": data.name,
        "owner_id": data.owner_id,
        "size": data.size,
        "type": data.type
      }

      logger.debug(`adding document (${document.name}) in debug`, document);

      const response = await httpClient.post(`/groups/${id}/documents`, document);
      return response;
      
    } catch (error) {
      logger.error(`Uploading document`, error);
      throw  error;
    }
  }

  const formatItineraryDayObject = data => {
    let object = {
      "breakfast": data.breakfast,
      "date": data.date,
      "description": data.description,
      "dinner": data.dinner,
      "image": data.image,
      "index": data.index,
      "mileage": data.mileage,
      "supper": data.supper,
      "title": data.title,
      "landing": data.landing,
      "boarding": data.boarding,
      "port_of_call": data.port_of_call,
      "cruise_day_type": data.cruise_day_type
    }

    return object;
  }

  return {
    createGroupWithBasicInformation,
    getGroupBasicInformation,
    getAllAvailableGroups,
    updateGroupBasicInformation,
    updateGroupAdvancedInformation,
    getGroupAdvancedInformation,
    uploadImage,
    addItineraryDayToGroup,
    updateItineraryDay,
    deleteItineraryDay,
    getGroupTransportOptions,
    getGroupAccommodationOptions,
    updateGroupStatus,
    updateAccessGroupStatus,
    getReservations,
    cloneGroup,
    deleteGroup,
    getDocuments,
    uploadDocument,
    addDocumentToGroup,
    deleteDocument,
    getOrgReservations
  };
};

import axios from "@axios";

export class GroupReservationService {
  constructor() {}
  async getReservations(group_id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/groups/${group_id}/reservations`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }
  async getReservation(group_id,reservation_id, publicAccessToken) {
    let url = `/groups/${group_id}/reservations/${reservation_id}`;

    if (publicAccessToken) {
      url += `?token=${publicAccessToken}`
    }

    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }
  async postReservationAll(group_id, data, publicAccessToken) {
    if (publicAccessToken == null) {
      let reservations = await this.getReservations(group_id);
      data.name = reservations.data.length + 1;
    } else {
      data.name = 'Guest Reservation';
    }
    // let reservationObject = {
    //   insurance_contract_number:data.insurance_and_payment.insurance_contract_number,
    //   insurer_name:data.insurance_and_payment.insurer_name,
    //   insurance_types:["medical"],
    //   has_trip_insurance:data.insurance_and_payment.has_trip_insurance,
    //   has_membership:data.insurance_and_payment.has_membership,
    //   extra_information:"some extra",
    //   wants_package_modification:"true",
    //   membership_number:data.insurance_and_payment.membership_number,
    //   membership_company:data.insurance_and_payment.membership_company,
    //   name:reservations.data.length+1,
    //   wants_insurance_submission:"true",
    //   seat_selection:"free",
    // };

    
    let url = `/groups/${group_id}/reservations`
    if (publicAccessToken != null) {
      url = `/groups/${group_id}/reservations?token=${publicAccessToken}`
    }

    return new Promise((resolve, reject) => {
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);

        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }
  async postPackageReservation(group_id, reservation_id,data,package_id, publicAccessToken) {
    let packageReservationObject = {
      extra_bed:false,
      bed_option:data.preferences.bed_option,
      playpen:false,
      birthday_celebration:false,
      honeymoon:false,
      floor_preference:data.preferences.bed_option,
      reduced_mobility:false,
      requires_wheelchair_assistance:false,
      package_id:package_id,
      occupation_type_id:data.occupation.id
    };

    let url = `/groups/${group_id}/reservations/${reservation_id}/package-reservations`;
    if (publicAccessToken) {
      url += `?token=${publicAccessToken}`
    }

    return new Promise((resolve, reject) => {
      axios
        .post(url, packageReservationObject)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }
  async updatePackageReservation(group_id, reservation_id, package_reservation_id, package_id, data, publicAccessToken) {
    let packageReservationObject = {
      extra_bed: data.extra_bed ?? false,
      bed_option:data.bed_option ?? 'NO_PREFERENCE',
      playpen:data.playpen ?? false,
      birthday_celebration:data.birthday_celebration ?? false,
      honeymoon:data.honeymoon ?? false,
      floor_preference:data.floor_preference ?? 'NO_PREFERENCE',
      reduced_mobility:data.reduced_mobility ?? false,
      requires_wheelchair_assistance:data.requires_wheelchair_assistance ?? false,
      package_id:package_id,
      occupation_type_id:data.occupation.id
    };

    let url = `/groups/${group_id}/reservations/${reservation_id}/package-reservations/${package_reservation_id}`;
    if (publicAccessToken) {
      url += `?token=${publicAccessToken}`
    }

    return new Promise((resolve, reject) => {
      axios
        .put(url, packageReservationObject)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }
  async postTravelerReservation(group_id,reservation_id,package_reservation_id, data, publicAccessToken) {
    
    let reservationOwner = false;

    if (data.reservation_owner !== undefined && data.reservation_owner !== null) {
      reservationOwner = data.reservation_owner
    }

    let travelerReservation = {
      reservation_owner:reservationOwner,
      gender:data.gender,
      passport: "passport",
      last_name:data.last_name,
      country: data.country,
      fully_vaccinated:false,
      requires_epipen_on_self: data.requires_epipen_on_self!==undefined?data.requires_epipen_on_self:false,
      email:data.email,
      allergies: data.allergies!==undefined?data.allergies:"NO_PREFERENCES",
      medical_condition_controlled:data.prior_medical_condition,
      emergency_contact_phone: data.emergency_contact_phone,
      city:data.city,
      has_membership: data.has_membership,
      membership_number: data.membership_number,
      membership_company: data.membership_company,
      phone:data.phone,
      province: data.province,
      address:data.address,
      middle_name: data.middle_name,
      emergency_contact_name:data.emergency_contact_name,
      has_allergies: data.has_allergies,
      first_name:data.first_name,
      birthday:data.birthday,
      age:this.calculateAge(data.birthday),
      medical_condition_stability: "three_To_six_Months",
      postal_code:data.postal_code,
      vaccination_type: "astra_only",
      prior_medical_condition:data.prior_medical_condition,
      type: data.type ?? 'ADULT',
      extra_information:"Extra info here",
      fully_vaccinated_before_trip: "true",
      preexisting_condition_details: data.preexisting_condition_details
    };
    
    let url = `/groups/${group_id}/reservations/${reservation_id}/package-reservations/${package_reservation_id}/travelers`;
    if (publicAccessToken) {
      url += `?token=${publicAccessToken}`
    }

    return new Promise((resolve, reject) => {
      axios
        .post(url, travelerReservation)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }
  calculateAge(birthday) {
    var ageInMilliseconds = new Date() - new Date(birthday);
    return Math.floor(ageInMilliseconds/1000/60/60/24/365);
  }
  async updateTravelerReservation(group_id,reservation_id,package_reservation_id,traveler_id, data, publicAccessToken) {
    let travelerReservation = {
      reservation_owner:data.reservation_owner!==undefined?data.reservation_owner:false,
      gender:data.gender,
      passport: "passport",
      last_name:data.last_name,
      country: data.country,
      fully_vaccinated:false,
      requires_epipen_on_self: data.requires_epipen_on_self!==undefined?data.requires_epipen_on_self:false,
      email:data.email,
      allergies: data.allergies!==undefined?data.allergies:"NO_PREFERENCES",
      medical_condition_controlled:data.prior_medical_condition,
      emergency_contact_phone: data.emergency_contact_phone,
      city:data.city,
      phone:data.phone,
      province: data.province,
      has_membership: data.has_membership,
      membership_number: data.membership_number,
      membership_company: data.membership_company,
      address:data.address,
      middle_name: data.middle_name,
      emergency_contact_name:data.emergency_contact_name,
      has_allergies: data.has_allergies,
      first_name:data.first_name,
      birthday: data.birthday,
      age:this.calculateAge(data.birthday),
      medical_condition_stability: "three_To_six_Months",
      postal_code:data.postal_code,
      vaccination_type: "astra_only",
      prior_medical_condition:data.prior_medical_condition,
      type: data.type ?? 'ADULT',
      extra_information:"Extra info here",
      fully_vaccinated_before_trip: "true",
      preexisting_condition_details: data.preexisting_condition_details

    };

    let url = `/groups/${group_id}/reservations/${reservation_id}/package-reservations/${package_reservation_id}/travelers/${traveler_id}`;
    if (publicAccessToken) {
      url += `?token=${publicAccessToken}`
    }

    return new Promise((resolve, reject) => {
      axios
        .put(url, travelerReservation)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }
  async deleteReservation(id,reservation_id) {

    return new Promise((resolve, reject) => {
      axios
        .delete(`/groups/${id}/reservations/${reservation_id}`).then(response => {
        resolve(response)
      })
        .catch(error => {
          if (error.response) {
            reject(error.response)
          } else {
            reject(error)
          }
        })
    })
  }
  async updateStatus(group_id,reservation_id,status, publicAccessToken) {
    let url = `/groups/${group_id}/reservations/${reservation_id}/status`;
    if (publicAccessToken) {
      url += `?token=${publicAccessToken}`
    }

    return new Promise((resolve, reject) => {
      axios
        .put(url,status)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }
  async updateReservationBookingNumbers(group_id, reservation_id, booking_numbers) {
    const bookingNumbersObj = {
      booking_numbers: booking_numbers
    }
    return new Promise((resolve, reject) => {
      axios
        .put(`/groups/${group_id}/reservations/${reservation_id}/booking_numbers`, bookingNumbersObj)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }
  async createReservationPayment(group_id, reservation_id, payment) {
    let url = `/groups/${group_id}/reservations/${reservation_id}/payments`;
    
    return new Promise((resolve, reject) => {
      axios
        .post(url, payment)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }
  async updateReservationPayment(group_id, reservation_id, payment_id, payment) {
    let url = `/groups/${group_id}/reservations/${reservation_id}/payments/${payment_id}`;
    
    return new Promise((resolve, reject) => {
      axios
        .put(url, payment)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }
  async deleteReservationPayment(group_id, reservation_id, payment_id) {
    let url = `/groups/${group_id}/reservations/${reservation_id}/payments/${payment_id}`;
    
    return new Promise((resolve, reject) => {
      axios
        .delete(url)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }
  async sendUserSummaryEmail(group_id, reservation_id, email, publicAccessToken) {
    let url = `/groups/${group_id}/reservations/${reservation_id}/emails/summary`;
    if (publicAccessToken) {
      url += `?token=${publicAccessToken}`
    }
    
    return new Promise((resolve, reject) => {
      axios
        .post(url, {email: email})
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }
  async updateAssignedAgent(group_id,reservation_id,agent) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/groups/${group_id}/reservations/${reservation_id}/user_id`,agent)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }
  async getUser(user_id, publicAccessToken) {
    let url = `/users/${user_id}`;
    if (publicAccessToken) {
      url += `?token=${publicAccessToken}`
    }

    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }
  async updateReservation(group_id,reservation_id, data, publicAccessToken) {
    let url = `/groups/${group_id}/reservations/${reservation_id}`;
    if (publicAccessToken) {
      url += `?token=${publicAccessToken}`
    }

    return new Promise((resolve, reject) => {
      axios
        .put(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }
  async deleteTraveler(group_id,reservation_id, package_reservation_id,traveler_id, publicAccessToken) {
    let url = `/groups/${group_id}/reservations/${reservation_id}/package-reservations/${package_reservation_id}/travelers/${traveler_id}`;
    if (publicAccessToken) {
      url += `?token=${publicAccessToken}`
    }

    return new Promise((resolve, reject) => {
      axios
        .delete(url)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }
  async deletePackageReservation(group_id,reservation_id, package_reservation_id, publicAccessToken) {
    let url = `/groups/${group_id}/reservations/${reservation_id}/package-reservations/${package_reservation_id}`;
    if (publicAccessToken) {
      url += `?token=${publicAccessToken}`
    }

    return new Promise((resolve, reject) => {
      axios
        .delete(url)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }
  async getAllAgencies() {

    return new Promise((resolve, reject) => {
      axios
        .get(`/agencies`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }
  async getUsersByAgencies(agency_id) {

    return new Promise((resolve, reject) => {
      axios
        .get(`users?resource=agency&id=${agency_id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }

  async getAllMembersOfOrg(publicAccessToken) {
    let url = `/organization/members`;

    if (publicAccessToken) {
      url += `?token=${publicAccessToken}`;
    }

    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }
  async updateReservationNote(group_id, reservation_id, note) {
    const bookingNoteObj = {
      notes: note
    }
    return new Promise((resolve, reject) => {
      axios
        .put(`/groups/${group_id}/reservations/${reservation_id}/notes`, bookingNoteObj)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }
}
